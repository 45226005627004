/*region 活动*/
.explore-huodong-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0 0 14px;
    border-bottom: solid 5px #F6F6F6;
    padding: 20px 0 15px;
}

.explore-huodong-pic {
    aspect-ratio: 143/91;
    width: 143px;
    height: 91px;
    background-color: #000000;
    border-radius: 4px;
}

.explore-huodong-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 14px;
    margin-left: 14px;
}

.explore-huodong-name {
    color: #2c2c2c;
    font-size: 14px;
    font-weight: 500;
    max-height: 2.6em;
    overflow-y: hidden;
    text-overflow: ellipsis;
    line-height: 1.3em;
}

.explore-huodong-date {
    color: #969696;
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 4px;
}

.explore-huodong-address {
    color: #969696;
    font-weight: 500;
    font-size: 12px;
}

/*endregion*/

.zp {
    display:block;
    border-bottom: solid 5px #F6F6F6;
    padding: 14px 0 9px;
}

.zp-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.zp-content {
    margin: 0 14px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.zp-title {
    color: #2c2c2c;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.zp-price {
    color: #44b6dd;
    font-size: 16px;
    font-weight: 500;
}

.zp-company {
    color: #969696;
    font-size: 14px;
    margin-bottom: 8px;
}

.zp-tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.zp-tag {
    color: #44b6dd;
    font-size: 13px;
    padding: 2px 6px;
    background: #44b6dd1a;
    margin-right: 10px;

}

.zp-hr {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.zp-hr-ico {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
}

.zp-hr-name {
    color: #2c2c2c;
    font-size: 12px;
}

.zp-address {
    color: #969696;
    font-size: 12px;
    text-align: right;
}

.no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /*vertical center */
    margin-top: 50%;
    transform: translateY(-50%);
    width:100%;
    color: #2c2c2c;
}
.no-data img{
    width: 202px;
    height: 119px;
}

/*pc版*/
@media only screen and (min-width: 394px) {
    .no-data{
        margin-top: 133px;
        transform: translateY(0);
        font-size: 20px;
    }
    .no-data img{
        width: 344px;
        height: 209px;
    }
    .pcrow-reverse {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        height: 75px;
        border-bottom: solid 1px #eaeaea;
        margin: 0 40px;
    }

    .ExplorePage .zhaopin-list {
        margin: 40px 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 11px;
        grid-row-gap: 14px;
    }
    .ExplorePage .huodong-list {
        margin: 40px 40px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 40px;
    }

    .ExplorePage .zp{
        border-radius: 4px;
        background: linear-gradient(#f6f6f6, #f6f6f6), #ffffff;
    }


    .explore-huodong-li {
        flex-direction: column;
        align-items: center;
        margin: 0;
        border-bottom: none 0 transparent;
        padding: 0 0 24px 0;
        background: #f6f6f6;

    }

    .explore-huodong-pic {
        aspect-ratio: 265/154;
        width: 100%;
        height: auto;
        border-radius: 0;
        margin:0 0 20px 0;
    }

    .explore-huodong-right {
        padding:0 8px;
        margin:0;
        width:100%;
    }

    .explore-huodong-name {
        font-size: 16px;
        margin:0 8px 0 8px;
        /*height: 91px;*/
        height: 2.6em;
    }

    .explore-huodong-right .pcrow{
        margin:20px 0 0 0;
        width: 100%;
        justify-content: space-between;
    }
    .explore-huodong-date {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 12px;
        margin-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .explore-huodong-address {
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: 8px;
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

/*pc加宽版*/
@media only screen and (min-width: 1221px) {
    .ExplorePage .huodong-list {
        grid-template-columns: repeat(5, 1fr);
    }
    .ExplorePage .zhaopin-list {
        grid-template-columns: repeat(4, 1fr);
    }
}