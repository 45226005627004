/*region banner*/
.banner {
    margin: 14px 14px;
    overflow-x: hidden;
    flex:1;
}

.banner .swiper-slide {
    aspect-ratio: 750/392;
    width: 100%;
    height: auto;
    background-color: #000;
    border-radius: 4px;
}
.banner .swiper-slide img,.banner .swiper-slide video{
    width: 100%;
    height: 100%;
    border-radius: 4px;
}
.swiper-wrapper {
    aspect-ratio:  750/392;

}

.swiper-pagination{
    z-index: 99;
    display:flex;
    flex-direction: row;
    gap:8px;
    justify-content: center;
}
.swiper-pagination .my-bullet {
    background-color: #ccc;
    width: 8px;
    height: 8px;
    border-radius: 4px;
}
.swiper-pagination .my-bullet-active{
    background-color: #44B6DD;
    width: 24px;
}

/*endregion*/

/*region 公告*/
.gonggao {
    margin: 14px 14px 20px;
    padding: 8px 11px;
    border-radius: 4px;
    background: #44b6dd1a;
    color: #44b6dd;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    position: relative;
    overflow: hidden;

}

.gonggao-ico {
    width: 14px;
    height: 16px;
    margin-right: 1px;
    margin-left: 1px;

}

.gonggao-tf {
    height: 1em;
    line-height: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gonggao-list-wrapper {
    display: flex;
    position: relative;

}

.gonggao .swiper-container {
    margin: 0;
    /*flex: 1;*/
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gonggao-list {
    height: 1em;
    line-height: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;

}

.gonggao-list .swiper-slide {
    height: 1em;
    line-height: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    overflow-x: hidden;
    display:block;
}

/*endregion*/


/*region 企业认证*/
.qyrz {
    background-position: center;
    background-image: url("../res/0306/bg.png");
    background-size: cover;
    aspect-ratio: 392/75;
    /*aspect-ratio: 786/270;*/
    border-radius: 4px;
    position: relative;
    width: 365px;
    margin:20px auto;
    color: #44B6DD;
    cursor: pointer;
}

.qyrz-ico {
    width: 21px;
    height: 17px;
    display: inline-block;
}

.qyrz-title {
    padding:12px 0 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #44B6DD;

}

.qyrz-tf {
    color: #44B6DD;
    font-size: 13px;
    margin-left: 12px;
    margin-top: 6px;
}

.qurenzheng {
    width: 59px;
    height: 26px;
    background-color: #44B6DD;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    top: 24.5px;
    right: 12px;
    position: absolute;
    border-radius: 4px;

}

/*endregion*/

/*region card-head*/
.card-head {
    margin: 20px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-title {
    color: #050505;
    font-weight: 600;
    font-size: 18px;
}

.card-rightbtn {
    margin-left: auto;
    color: #44b6dd;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-rightbtn img {
    width: 20px;
    height: 20px;
}

/*endregion*/

/*region 园区服务*/
.yqfw {
    margin: 0 14px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /*grid-template-columns: repeat(auto-fill, 56px);*/
    /*background-color: #d0d0d0;*/
    grid-row-gap: 20px;
}

.fuwu-li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fuwu-li img {
    width: 32px;
    height: 32px;
    margin-bottom: 4px;
}

.fuwu-name {
    text-align: center;
    color: #2c2c2c;
    font-weight: 500;
    font-size: 14px;
}

/*endregion*/
/*region 活动*/
.huodong{
    margin: 0 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 11px;
    grid-row-gap: 14px;
}
.huodong-pic {
    width: 100%;
    aspect-ratio: 177/102;
    background-color: #44B6DD;
}
.huodong-li{
    background-color: #F6F6F6;
}
.huodong-name {
    color: #2c2c2c;
    font-weight: 500;
    font-size: 14px;
    margin: 10px 8px 12px;
    height: 2.6em;
    line-height: 1.3em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.huodong-info{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 8px 12px;
    column-gap: 10px;
}

.huodong-date {

    color: #969696;
    font-weight: 500;
    font-size: 12px;
}

.huodong-address {
    color: #969696;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
}

/*endregion*/


/*region pc适配 */
/*pc版*/
@media only screen and (min-width: 394px) {
    .pcrow{
        display:flex;flex-direction: row;
    }
    .pccol{
        display:flex;flex-direction: column;
    }
    .banner {
        margin: 40px 20px 14px 40px;
        overflow-x: hidden;
    }

    .banner .swiper-slide {
        aspect-ratio: 740/349;
        width: 100%;
        height: auto;
        background-color: #000;
        border-radius: 4px;
    }
    .banner .swiper-slide img,.banner .swiper-slide video{
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    .swiper-wrapper {
        aspect-ratio: 740/349;
    }


    .gonggao{
        width: 340px;
        margin: 40px 40px 0 0;
        padding: 8px 10px;
        border-radius: 4px 4px 0 0;
        background: #44B6DD;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        position: relative;
    }

    .gonggao-ico {
        width: 14px;
        height: 16px;
        margin-right: 3px;
        margin-left: 1px;

    }

    .gonggao-list-pc .gonggao-li{
        font-size: 13px;
        max-height: 2.1em;
        /*line-height: 1.1em;*/
        text-overflow: ellipsis;
        white-space: nowrap;
        color:#44B6DD;
        margin: 8px 0 8px 0;
        display:block;
        overflow-x: hidden;
        overflow-y: visible;

    }

    .gonggao-list-pc {
        background: #44B6DD1A;
        width: 340px;

        display: flex;
        flex-direction: column;

        padding:8px 10px 0 10px;
        margin:0 0px 20px 0px;
        flex:1;
    }

    .qyrz {
        width: 360px;
        margin:0 0 14px 0;
    }

    .card-title {
        font-size: 28px;
        display:flex;flex-direction: row;
        align-items: center;
    }
    .card-head {
        margin: 20px 40px;
    }
    .card-title img{
        width:30px;height:30px;margin-right: 5px;
    }
    .huodong {
        margin: 0 40px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 11px;
        grid-row-gap: 14px;
    }
    .ServicesPage .card-title{
        font-size: 16px;
        margin-top: 20px;
    }

    .ServicesPage .yqfw {
        margin: 0 40px;
        display: flex;
        flex-direction: row;
        column-gap: 42px;
    }
    .fuwu-li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
    }
    .fuwu-li img{
        width: 20px;
        height: 20px;
        margin-right: 4px;
        margin-bottom: 0;
    }

}

/*pc加宽版*/
@media only screen and (min-width: 1221px) {
    .gonggao-list-pc {
        width: 447px;
    }
    .gonggao{
        width: 447px;
    }
    .huodong {
        grid-template-columns: repeat(5, 1fr);
    }
    .qyrz {
        width: 467px;
    }
}
/*mobile*/
@media only screen and (max-width: 393px) {
    /*region 微信上设置了加大字号之后文字会被错位截断的问题 */
    /*以下设置 是微信上设置了加大字号之后文字会被错位截断的问题 （swiper和微信不兼容）*/
    .gonggao-tf {
        height: 16px;
        line-height: 16px;
    }
    .gonggao-list {
        height: 16px;
        line-height: 16px;
    }
    .gonggao-list .swiper-slide {
        height: 16px;
        line-height: 16px;
    }
    /*endregion*/
}

/*endregion*/