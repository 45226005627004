.SearchBar {
    margin: 10px 14px 20px;
    position: relative;
    height: 34px;
    width: auto;
    border-radius: 19px;
    background-color: #F6F6F6;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.search-icon{
    width:16px;height: 16px;
    position: absolute;
    left:12px;
}
input.search-tf{
    border:none;
    background: transparent;
    flex:1;
    outline: none;
    -webkit-appearance: none;
    padding:2px 12px 2px 36px;
}

/*pc版*/
@media only screen and (min-width: 394px) {
    .SearchBar{
        margin:0;
        min-width: 236px;
    }
    .search-icon{
        right: 12px;left:auto;
    }
    input.search-tf {
        padding: 2px 36px 2px 12px;
    }
}