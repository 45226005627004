.logotitle {

    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logotitle img {
    width: 261px;
    height: 57px;
}


.toptab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 15px;
    margin-left: auto;
    margin-right: 40px;
}
.topbar .topbar-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toptab-tabli.active {
    border-radius: 33px;
    color: #fff;
    background: #44b6dd;
}

.toptab-tabli {
    border-radius: 33px;
    padding: 5px 21px;
    color: #2C2C2C;
}

.toptab-tabli-name {
    font-size: 18px;
    font-weight: 500;
}

.topmenu {
    margin: 0 0px;
    padding: 0 40px;
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 3px #0000001a;
    column-gap: 20px;
    position: relative;
}
.topmenu-more:hover,
.topbar .fuwu-li:hover .fuwu-name{
    color: #44B6DD;
}

.topmenu-more {
    color: #2c2c2c;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}

.topmenu-more-icon.active {
    display: none;
}
.topmenu-more:hover .topmenu-more-icon.active{display: block;}
.topmenu-more:hover .topmenu-more-icon{display: none;}
.topmenu-more-icon {
    width: 16px;
    height: 16px;
}

.topmenu-all {
    margin: 3px 0 0;
    padding: 0 40px 40px;

    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 3px #0000001a;

    z-index: 2;
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    right: 0;

    max-height:600px;
    transition: max-height 0.4s ease-out 0s, opacity 0.4s ease-out 0s;
}
.topmenu-all.hidden{
    max-height: 0;
    padding: 0 40px 0px;
    overflow: hidden;
    opacity: 0;
}

.fuwu-grouptitle {
    font-size: 16px;
    font-weight: 500;
    color: #050505;
    margin-bottom: 16px;
    margin-top: 20px;
}

.fuwu-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 42px;

}


.topbar .fuwu-li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topbar .fuwu-li img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.topbar .fuwu-name {
    text-align: center;
    color: #2c2c2c;
    font-weight: 500;
    font-size: 14px;
}

/*pc加宽版*/
@media only screen and (min-width: 1221px) {
    .topmenu {
        column-gap: 42px;
    }
}
