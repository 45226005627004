.tabbar {
    margin-left: 14px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
}

.tabli {
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 500;
    margin: 0 ;
}

.tabli.active {
    color: #44b6dd;
    font-size: 20px;
}

/*pc版*/
@media only screen and (min-width: 394px) {
    .tabbar {
        margin: 0;
    }

    .tabli {
        color: #2C2C2C;
    }

    .tabli.active {
        font-size: 26px;
        color: #44b6dd;
    }
}