.footer-before{
    height :90px;
    padding-bottom: env(safe-area-inset-bottom);
}
.footer {
    position: fixed;
    bottom:0;
    right:0;
    left:0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    background: #ffffff;
    box-shadow: 0px -1px #0000001a;
}
.footer-ver{
    margin:20px;
    font-size: 12px;
    color: #5c5c5c;
    text-align: center;
}
.footer-ver a{
    /*color: #44B6DD;*/
    font-weight: bold;
}

.footer-tabli {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    flex:1;
}

.footer-tabli.active {

}

.footer-tabicon {
    width: 32px;
    height: 32px;
}

.active .footer-tabli-name {
    color: #44b6dd;
    font-weight: 500;
}
.footer-tabli-name {
    color: #2C2C2C;
    font-size: 12px;
}

/*pc版*/
@media only screen and (min-width: 394px) {

    .footer {
        position: relative;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding-bottom: env(safe-area-inset-bottom);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        background: #ffffff;
        box-shadow: 0px -1px #0000001a;
        /*box-shadow: 0px -3px 3px #0000001a;*/

        color: #5c5c5c;
        font-size: 12px;
        margin-top: 80px;
    }
    .footer-ver{
        margin:20px;
    }

}
/*pc加宽版*/
@media only screen and (min-width: 1221px) {
    .ExplorePage .huodong-list {
        grid-template-columns: repeat(5, 1fr);
    }
    .ExplorePage .zhaopin-list {
        grid-template-columns: repeat(4, 1fr);
    }
}